import React, { FC } from 'react';
import { fetchUtils, Admin as ReactAdmin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import authProvider from './authProvider';

import { UserList, UserEdit } from './Users';
import { EventsList, EventEdit, EventCreate } from './Events';

import { Login } from "./views"

const httpClient = (url: any, options: any) => {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider('api/v1', httpClient);

export const Admin: FC = () => {
  return (
    <ReactAdmin loginPage={Login} dataProvider={dataProvider} authProvider={authProvider}>
      {(permissions) => [

        <Resource
          name="events"
          list={EventsList}
          create={EventCreate}
          edit={EventEdit}
        />,
        permissions === 'admin' ? <Resource
          name="users"
          list={UserList}
          edit={UserEdit}
        // create={UserCreate}
        /> : null,
      ]}

    </ReactAdmin>
  );
};
