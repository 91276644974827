import React, { FC } from 'react';

import { Edit } from 'react-admin';

import EventEditForm from './EventEditForm';

export const EventEdit: FC = (props) => {
  return (
    <Edit undoable={false} {...props}>
      <EventEditForm />
    </Edit >)
}