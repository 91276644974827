import React, { FC } from "react";

import { gameValueTypeNames, gameValueTypes, constValueTypes } from "./dataDicts"
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * > input': {
      color: '#000000 !important',
    }
  },
}));


const getGameValue = (gameValueArg: any) => {
  const { gameTypeValue = "", gameValue = "" } = gameValueArg

  if (gameTypeValue === "liga") return gameValueTypeNames[gameTypeValue]

  if (gameValueTypes[gameTypeValue] && gameValueTypes[gameTypeValue][gameValue]) {
    return gameValueTypes[gameTypeValue][gameValue]
  }
  return ""
}

const getConstValue = (constValueArg: any) => {
  const { constType = "", constValue = "" } = constValueArg

  if (constValueTypes[constType] && constValueTypes[constType][constValue]) {
    return constValueTypes[constType][constValue]
  }

  return ""
}

const getPartValue = (partData: any) => {
  const { valueType = "" } = partData
  switch (valueType) {
    case "game":
      const { gameValue = {} } = partData
      return getGameValue(gameValue)
    case "const":
      const { constValue = {} } = partData
      return getConstValue(constValue)
    case "number":
      const { numberValue = "" } = partData
      return numberValue
  }
  return ""
}

export const RulePreview: FC<{ record: any }> = (props) => {
  const classes = useStyles();
  if (!props.record) return null


  const { comparison = "", left_part = {}, right_part = {} } = props.record
  const leftPart = getPartValue(left_part)
  const rightPart = getPartValue(right_part)

  return <>{`${leftPart} ${comparison} ${rightPart}`}</>
}