import React, { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  DateInput,
} from 'react-admin';

export const UserEdit: FC = (props) => (
  <Edit undoable={false} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="username" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="vk_id" />
      <TextInput source="tg_id" />
      <TextInput disabled source="auth_id" />
      <TextInput disabled source="auth_type" />

      <BooleanInput source="is_active" label="Пользователь активен" />
      <BooleanInput source="is_superuser" label="Админ" />
      <BooleanInput source="subscribe_active" label="Подписка" />
      <DateInput source="subscribe_end" label="Окончание подписки" />
    </SimpleForm>
  </Edit>
);
