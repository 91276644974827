import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { YMInitializer } from 'react-yandex-metrika';

ReactDOM.render(
  <Router>
    <App />
    <YMInitializer
      accounts={[82561603]}
      options={{
        webvisor: true, clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true
      }}
      version="2" />
  </Router>,
  document.getElementById('root')
);
