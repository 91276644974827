import decodeJwt from 'jwt-decode';

function getCookie(name: any) {

  var matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ))
  return matches ? decodeURIComponent(matches[1]) : undefined
}

const authProvider = {
  login: (auth_tg_data: any) => {
    const tokken_api_url = process.env.NODE_ENV === 'development' ? '/api/dev_token' : '/api/token'
    const request = new Request(tokken_api_url, {
      method: 'POST',
      body: JSON.stringify(auth_tg_data),
      headers: { 'Content-Type': 'application/json' },
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token }) => {
        const decodedToken: any = decodeJwt(access_token);
        localStorage.setItem('token', access_token);
        localStorage.setItem('permissions', decodedToken.permissions);
        localStorage.setItem('auth', JSON.stringify(decodedToken));
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('auth');
    document.cookie = 'X-AUTH-TOKEN=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    return Promise.resolve();
  },
  checkError: (error: { status: number }) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    if (localStorage.getItem('token')) return Promise.resolve()
    const access_token = getCookie('X-AUTH-TOKEN')
    if (access_token) {
      const decodedToken: any = decodeJwt(access_token);
      localStorage.setItem('token', access_token);
      localStorage.setItem('permissions', decodedToken.permissions);
      localStorage.setItem('auth', JSON.stringify(decodedToken));
      return Promise.resolve()
    }
    return Promise.reject()
  },
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  },
  getIdentity: () => {
    try {
      const auth_item: any = localStorage.getItem('auth')
      if (auth_item) {
        const { id, fullName, avatar } = JSON.parse(auth_item);
        return Promise.resolve({
          id,
          fullName,
          avatar,
        });
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export default authProvider;
