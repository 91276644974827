import React, { FC } from 'react';

import EventEditForm from './EventEditForm';

import { Create } from 'react-admin';

export const EventCreate: FC = (props) => {
  return (
    <Create {...props}>
      <EventEditForm />
    </Create >)
}