import React, { FC } from 'react';

import { useLogin, useNotify, Notification, defaultTheme } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, makeStyles,withStyles } from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';

import Button from '@material-ui/core/Button';

import TelegramLoginButton from 'react-telegram-login';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#282c34',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: 'white',
  },
  margin: {
    margin: theme.spacing(2),
  },
  socBut: {
    marginBottom: '20px',
    width: '100%',
    height: '40px'
  },
  padding: {
    padding: theme.spacing(1),
  },
  marginTop: {
    marginTop: 10,
  },
}));


const VKButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#4C75A3"),
    backgroundColor: "#4C75A3",
    '&:hover': {
      backgroundColor: "#4C75A3",
    },
  },
}))(Button);

const GButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#DB4437"),
    backgroundColor: "#DB4437",
    '&:hover': {
      backgroundColor: "#DB4437",
    },
  },
}))(Button);

export const Login: FC = () => {
  const classes = useStyles();
  const login = useLogin();
  const notify = useNotify();

  const handleTelegramResponse = async (auth_tg_data: any) => {
    login(auth_tg_data).catch((e: any) => {
      console.log(e)
      notify("Auth err")
    })
  };

  const handleDevResponse = async (e: any) => {
    login("123").catch(() => {
      notify("Dev auth err")
    })
  };


  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <div className={classes.header}>
        <Paper className={classes.padding}>
          <div className={classes.margin}>
            <VKButton 
              variant="contained"
              className={classes.socBut}
              onClick={(e) => {
                e.preventDefault();
                window.location.href='/api/login/vk';
              }} >
              VK
            </VKButton>
            <GButton 
              variant="contained"
              className={classes.socBut}
              onClick={(e) => {
                e.preventDefault();
                window.location.href='/api/login/google';
              }} >
              Google
            </GButton>
            <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="mk_event_bot" usePic="false" cornerRadius="5"/>
            {/* <a href="/api/login/google">GOOGLE</a> */}
            {process.env.NODE_ENV === 'development' && (<Button onClick={handleDevResponse}> "Dev auth"</Button>)}
          </div>
        </Paper>
        <Notification />
      </div>
    </ThemeProvider >

  );

}