import React, { FC } from 'react';

import { useQuery } from 'react-admin';
import { DataGrid, GridRowsProp, GridColDef, GridCellParams } from '@material-ui/data-grid';
import { Button } from '@material-ui/core';

export const EventsPanel: FC = (props: any) => {
  const { data, loading, error } = useQuery({
    type: 'getList',
    resource: `events_admin/${props.id}`,
    payload: { pagination: { page: 1, perPage: 100 }, sort: { field: "id", order: "DESC" }, filter: {} }
  });


  const rows: GridRowsProp = data ? data.map((r: any) => Object.keys(r).reduce((a, i) => ({ ...a, [i]: r[i] }), {})) : []
  const columns: GridColDef[] = [
    { field: 'id' },
    { field: 'name', headerName: "Имя", width: 250 },
    { field: 'is_active', headerName: "Активно", type: 'boolean', width: 150 },
    {
      field: '',
      headerName: 'Редактировать',
      width: 150,
      renderCell: (params: GridCellParams) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={()=>{
              props.history.push(`/events/${params.id}`)
            }}
          >
            Редактировать
          </Button>
        </strong>
      ),
    },
  ]
  return (<div style={{ height: 400, width: '100%' }}>
    <DataGrid rows={rows} columns={columns} disableColumnMenu disableColumnReorder />

  </div>)
}
