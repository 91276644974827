import React, { FC } from "react";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  SimpleForm,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer
} from 'react-admin';


import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiBox from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';

import { PartRuleEditor, ComoarateSelector, RulePreview } from '../../Components/RulesEditor'

import MsgEditor from '../../Components/MsgEditor'


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    minWidth: '100%',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 10,
    '&$expanded': {
      minHeight: 10,
    },
  },
  content: {
    '&$expanded': {
      margin: '10px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const Box = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    padding: "5px"
  }
})(MuiBox);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: '8px 16px'
  },
}))(MuiAccordionDetails);

const required = (message = 'Не может быть пустым') => (value:any) => value ? undefined : message;
const validateName = [required()]
const validateMsg = [required()]

const EventEditForm: FC = (props) => {
  return <SimpleForm {...props}>
    <TextInput source="name" label="Имя" validate={validateName} />
    <BooleanInput source="is_active" label="Активно" />
    <BooleanInput source="show_game_link" label="Ссылка на бой" />
    <Accordion >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Правила
      </AccordionSummary>
      <AccordionDetails>
        <ArrayInput source="rules" label="Правила">
          <SimpleFormIterator>

            <FormDataConsumer>
              {({ getSource, scopedFormData }: { getSource?: any, scopedFormData?: any }) => {
                return (
                  <div>
                    <Accordion  >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <RulePreview record={scopedFormData} />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Box>
                              <PartRuleEditor source={getSource('left_part')} />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Box>
                            <ComoarateSelector source={getSource('comparison')} />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <Box>
                            <PartRuleEditor source={getSource("right_part")} />
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              }}
            </FormDataConsumer>


          </SimpleFormIterator>
        </ArrayInput>
      </AccordionDetails>
    </Accordion>
    <TextInput source="msg_template" label="Текст сообщения" validate={validateMsg} />
      

  </SimpleForm>
}

export default EventEditForm