export const gameValueTypeNames: { [key: string]: string } = {
  liga: "Лига",
  boec: "Боец",
  total: "Тотал",
  time: "Время",
  rounds: "Раунды",
  kef: "Коэффициент",
  pregame_kef: "Коэффициент до начала",
}

export const ligas: { [key: string]: string } = {
  "1252965": "Mortal Kombat X",
  "1961974": "Mortal Kombat 11 (BO3)",
  "2057351": "Mortal Kombat 11 (BO9)",
  "2068436": "Mortal Kombat 11"
}

export const boecs: { [key: string]: string } = {
  "371201": "Тремор",
  "371205": "Джейсон Вурхиз",
  "371209": "Такеда Такахаши",
  "371211": "Милина",
  "371219": "Джакс",
  "371221": "Шиннок",
  "374393": "Хищник",
  "377319": "Куан Чи",
  "377321": "Кенши",
  "377323": "Рептилия",
  "377349": "Эрмак",
  "377351": "Кунг Джин",
  "377353": "Горо",
  "377355": "Таня",
  "377361": "Ферра и Торр",
  "454341": "Триборг",
  "457061": "Кожаное лицо",
  "504009": "Бо Рай Чо",
  "506723": "Чужой",
  "371203": "Лю Кенг",
  "371207": "Джэки Бриггс",
  "371213": "Саб-Зиро",
  "371215": "Коталь Кан",
  "371217": "Кунг Лао",
  "371223": "Джонни Кейдж",
  "377309": "Эррон Блэк",
  "377311": "Кэсси Кейдж",
  "377315": "Соня Блейд",
  "377317": "Китана",
  "377325": "Райдэн",
  "377329": "Ди'Вора",
  "377333": "Скорпион",
  "377363": "Кано",
  "2824823": "Коллектор",
  "2824825": "Скарлет",
  "2824831": "Кабал",
  "2825405": "Герас",
  "2825407": "Барака",
  "2825409": "Цетрион",
  "2825411": "Джейд",
  "2825413": "Нуб Сайбот",
  "2825423": "Джакс Бриггс",
  "4798361": "Джокер",
  "4825679": "Шан Цзун",
  "4825681": "Спаун",
  "4825683": "Терминатор",
  "4825687": "Ночной Волк",
  "4825689": "Фуджин",
  "4825691": "Шао Кан",
  "4825693": "Фрост",
  "4825695": "Шива",
  "4825727": "Робокоп",
  "4825765": "Синдел"
}

export const kefs: { [key: string]: string } = {
  sp1: "Победа в раунде П1",
  sp2: "Победа в раунде П2",
  sp_fav: "Победа в раунде фаворита",
  sp_aut: "Победа в раунде аутсайдера",
  x2p1: "1x2 П1",
  x2p2: "1x2 П2",
  sbr: "Добивание Бруталити",
  sfr: "Добивание Фаталити",
  srr: "Без добивания",
  sfsr: "Добивание FS",
  scp: "Чистая победа в раунде - Да",
  scp_no: "Чистая победа в раунде - Нет",
}

export const dobivs: { [key: string]: string } = {
  R: "Без добивания",
  F: "Фаталити",
  B: "Бруталити",
  FS: "FS"
}

export const gameValueTypes: { [key: string]: { [key: string]: string } } = {
  boec: {
    p1: "П1",
    p2: "П2",
    fav: "Фаворит",
    aut: "Аутсайдер",
    p: "Боец с любой стороны"
  },
  total: {
    common: "Общий тотал",
    p1: "Тотал П1",
    p2: "Тотал П2",
    fav: "Тотал фаворита",
    aut: "Тотал аутсайдера",
  },
  time: {
    timep1b: "Время минимальное (сек.)",
    time1m: "Время минимальное меньше(кэф)",
    time1b: "Время минимальное больше(кэф)",
    timep2b: "Время среднее (сек.)",
    time2m: "Время среднее меньше(кэф)",
    time2b: "Время среднее больше(кэф)",
    timep3b: "Время максимальное (сек.)",
    time3m: "Время максимальное меньше(кэф)",
    time3b: "Время максимальное больше(кэф)",
  },
  rounds: [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce((rounds: any, index: any): any => {
    rounds[`r${index}w`] = `Победитель ${index} раунда`
    rounds[`r${index}wt`] = `Добивание ${index} раунда`
    rounds[`r${index}time`] = `Время ${index} раунда`
    return rounds
  }, {}),
  kef: kefs,
  pregame_kef: kefs
}

export const constValueTypeNames: { [key: string]: string } = {
  liga: "Лига",
  opp: "Боец",
  dobiv: "Добивание"
}

export const constValueTypes: { [key: string]: { [key: string]: string } } = {
  dobiv: dobivs,
  opp: boecs,
  liga: ligas
}