// in src/users.js
import React, { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  DateField,
  TextInput,
} from 'react-admin';

import { useListContext } from 'react-admin';
import { Button, Toolbar } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import { EventsPanel } from "./UserEvents"

const PostPagination: any = () => {
  const { page, perPage, total, setPage } = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;
  return (
    nbPages > 1 &&
    <Toolbar>
      {page > 1 &&
        <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
          <ChevronLeft />
          Prev
        </Button>
      }
      {page !== nbPages &&
        <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
          Next
          <ChevronRight />
        </Button>
      }
    </Toolbar>
  );
}

const usersFilters = [
  <TextInput label="Логин" source="username" alwaysOn />,
  <TextInput label="Телега" source="tg_id" />,
  <TextInput label="VK" source="vk_id" />,
];

export const UserList: FC = (props) => (
  <List {...props} pagination={<PostPagination  {...props}/>} filters={usersFilters} >
    <Datagrid expand={<EventsPanel {...props}/>}>
      <TextField source="id" />
      <TextField source="tg_id" />
      <TextField source="username" label="Логин" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <BooleanField source="is_active" label="Пользователь активен" />
      <BooleanField source="is_superuser" label="Админ" />
      <BooleanField source="subscribe_active" label="Подписка" />
      <DateField source="subscribe_end" label="Окончание подписки" />
      <EditButton label="Редактировать" />
    </Datagrid>
  </List>
);
