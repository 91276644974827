import React, { FC, useState, useEffect } from 'react'

import { useInput } from 'react-admin';

import type { InputProps } from 'ra-core'

import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from '@material-ui/core';

import { gameValueTypeNames, gameValueTypes, constValueTypes, constValueTypeNames } from "./dataDicts"

export interface TypeValueSelectorProps {
  value: string
  onChange: any
}


const TypeValueSelector: FC<TypeValueSelectorProps> = (props) => {

  const { value, onChange } = props;
  const itemns: { [key: string]: string } = {
    "game": "Игра",
    "const": "Константа",
    "number": "Число"
  }
  return (
    <MyValueSelector items={itemns} value={value} label="Источник значения" onChange={onChange} />
  )
}

export interface MyValueSelectorProps {
  items: { [key: string]: string }
  value?: string
  label: string
  onChange?: any
}

export const MyValueSelector: FC<MyValueSelectorProps> = (props) => {
  const { items, value, label, onChange } = props

  return (
    <FormControl>
      {/* <FormLabel component="legend">Value type</FormLabel> */}
      <InputLabel id="label">{label}</InputLabel>
      <Select labelId="label" id="select" style={{ minWidth: '150px', }} value={value} onChange={onChange}>
        {Object.keys(items).map(key => <MenuItem value={key}>{items[key]}</MenuItem>)}
      </Select>
    </FormControl>)
}


export const GameValueSelector: FC<{ value: any, onChange: any }> = (props) => {
  const { onChange, value } = props;
  const [gameTypeValue, setGameTypeValue] = useState(value.gameTypeValue || "")
  const [gameValue, setGameValue] = useState(value.gameValue || "")

  const handleChangeTypeValue = (event: any) => {
    setGameTypeValue(event.target.value);
  }
  const handleChangeValue = (event: any) => {
    setGameValue(event.target.value);
  }

  useEffect(() => {
    onChange({
      gameTypeValue,
      gameValue
    })
  }, [gameTypeValue, gameValue])


  return (
    <>
      <MyValueSelector items={gameValueTypeNames} value={gameTypeValue} label="Значение" onChange={handleChangeTypeValue} />
      {gameValueTypes[gameTypeValue] && (<MyValueSelector items={gameValueTypes[gameTypeValue]} value={gameValue} onChange={handleChangeValue} label="Значение" />)}
    </>

  )
}

export const ConstValueSelector: FC<{ value: any, onChange: any }> = (props) => {
  const { onChange, value } = props;
  const [constType, setConstType] = useState(value.constType || "")
  const [constValue, setConstValue] = useState(value.constValue || "")

  const handleChangeConstType = (event: any) => {
    setConstType(event.target.value)
    setConstValue("")
  }
  const handleChangeConstValue = (event: any) => {
    setConstValue(event.target.value)
  }

  useEffect(() => {
    onChange({
      constType,
      constValue
    })
  }, [constType, constValue])

  return (<>
    <MyValueSelector items={constValueTypeNames} value={constType} label="Справочник" onChange={handleChangeConstType} />
    {constValueTypes[constType] && <MyValueSelector items={constValueTypes[constType]} value={constValue} label={constValueTypeNames[constType]} onChange={handleChangeConstValue} />}
  </>)
}

export const ComoarateSelector: FC<InputProps> = (props) => {
  const { input: { value, onChange } } = useInput(props as InputProps);
  const itemns: { [key: string]: string } = {
    "=": "=",
    ">": ">",
    "<": "<",
    "!=": "Не равно"
  }
  return (
    <FormControl style={{ minWidth: '100%' }}>
      <MyValueSelector items={itemns} value={value} label="Оператор сравнения" onChange={onChange} />
    </FormControl>
  )
}


export const PartRuleEditor: FC<InputProps> = (props) => {
  const { input: { value, onChange } } = useInput(props as InputProps);
  const [valueType, setValuetype] = useState(value.valueType || 'game')
  const [gameValue, setGameValue] = useState(value.gameValue || {})
  const [constValue, setConstValue] = useState(value.constValue || {})
  const [numberValue, setNumberValue] = useState(value.numberValue || "")

  const handleChange = (event: any) => {
    setValuetype(event.target.value);
  };
  const handleChangeGameValue = (value: any) => {
    setGameValue(value);
  };
  const handleChangeConstValue = (value: any) => {
    setConstValue(value);
  };
  const handleChangeNumberValue = (event: any) => {
    setNumberValue(event.target.value);
  };

  useEffect(() => {
    onChange({
      valueType,
      gameValue,
      constValue,
      numberValue
    })
  }, [
    valueType,
    gameValue,
    constValue,
    numberValue
  ])


  return (

    <FormControl style={{ minWidth: '100%' }}>

      <TypeValueSelector value={valueType} onChange={handleChange} />
      {valueType === 'game' && (<GameValueSelector value={gameValue} onChange={handleChangeGameValue} />)}
      {valueType === 'const' && (<ConstValueSelector value={constValue} onChange={handleChangeConstValue} />)}
      {valueType === 'number' && (<TextField value={numberValue} onChange={handleChangeNumberValue} label="Значение" />)}
    </FormControl>
  )


}
